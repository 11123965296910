const CONFIG_REQUEST = 'CONFIG_REQUEST';
const CONFIG_REQUEST_SUCCESS = 'CONFIG_REQUEST_SUCCESS';
const CONFIG_REQUEST_FAIL = 'CONFIG_REQUEST_FAIL';
const SET_PAGE_CONFIG = 'SET_PAGE_CONFIG';
const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';
const SEND_TAGS="SEND_TAGS";
const SET_TAGGING_SHEET = 'SET_TAGGING_SHEET';

const fetchConfig = () => ({
  type: CONFIG_REQUEST
});

const setPageConfig = payload => ({
  type: SET_PAGE_CONFIG,
  payload
});

const setTaggingSheet = taggingSheet => ({
  type: SET_TAGGING_SHEET,
  payload: { taggingSheet }
});

export {
  CONFIG_REQUEST,
  CONFIG_REQUEST_SUCCESS,
  CONFIG_REQUEST_FAIL,
  SET_PAGE_CONFIG,
  fetchConfig,
  setPageConfig,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_FAILURE,
  SEND_TAGS,
  setTaggingSheet,
  SET_TAGGING_SHEET
};

import { START_LOADER, STOP_LOADER } from "actions/loaderActions";

const initialState = 0;

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADER:
      return state + 1;
    case STOP_LOADER:
      return state - 1;
    default:
      return state;
  }
};

export default loaderReducer;

/* eslint-disable no-param-reassign */
import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import useRefreshTimeout from '../hooks/useRefreshTimeout';
import { refreshToken } from '../utils/refreshToken';

const RefreshToken = ({ axios }) => {
  const [isAuthApp, expireAt] = useSelector(state => [
    state?.authentication?.expires_in, // azure expireIn
    state?.authentication?.agentTokenDetails?.expires_at //agent expire at with unix timestamp
  ]);

  const storeToken = useSelector(state => state.config?.data?.storeToken);

  const applyRefreshToken = useCallback(refreshToken, [expireAt]);

  const agentDate = new Date(expireAt * 1000); // convert unix timestamp to date

  const isExpired = Math.floor((agentDate - new Date()) / 60000) <= 0; //Over a minute

  let expireTimeInMS = isExpired ? 0 : (agentDate - new Date()) / 2;

  useRefreshTimeout(
    isAuthApp,
    isExpired,
    () => {
      applyRefreshToken(axios, storeToken);
    },
    expireTimeInMS,
    storeToken
  );

  return null;
};

export default memo(RefreshToken);

import * as serviceWorker from "./serviceWorker";
import { Provider } from 'react-redux';
import { store } from 'store/configureStore';
import "./index.scss";


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  <Provider store={store}>
   <App />
  </Provider>, 
document.getElementById('root'));


serviceWorker.unregister();

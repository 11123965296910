import * as React from 'react';
import clsx from 'clsx';
import './styles.css';

const Grid = React.forwardRef(function Grid(props, ref) {
  const {
    alignContent = 'stretch',
    alignItems = 'stretch',
    className: classNameProp,
    component: Component = 'div',
    container = false,
    direction = 'row',
    item = false,
    justify,
    justifyContent = 'flex-start',
    lg = false,
    md = false,
    sm = false,
    spacing = 0,
    wrap = 'wrap',
    xl = false,
    xs = false,
    zeroMinWidth = false,
    ...other
  } = props;
  const classes = {
    root: 'sss-root',
    container: 'sss-container',
    item: 'sss-item',
    zeroMinWidth: 'sss-zeroMinWidth',
    'direction-xs-column': 'sss-direction-xs-column',
    'direction-xs-column-reverse': 'sss-direction-xs-column-reverse',
    'direction-xs-row-reverse': 'sss-direction-xs-row-reverse',
    'wrap-xs-nowrap': 'sss-wrap-xs-nowrap',
    'wrap-xs-wrap-reverse': 'sss-wrap-xs-wrap-reverse',
    'align-items-xs-center': 'sss-align-items-xs-center',
    'align-items-xs-flex-start': 'sss-align-items-xs-flex-start',
    'align-items-xs-flex-end': 'sss-align-items-xs-flex-end',
    'align-items-xs-baseline': 'sss-align-items-xs-baseline',
    'align-content-xs-center': 'sss-align-content-xs-center',
    'align-content-xs-flex-start': 'sss-align-content-xs-flex-start',
    'align-content-xs-flex-end': 'sss-align-content-xs-flex-end',
    'align-content-xs-space-between': 'sss-align-content-xs-space-between',
    'align-content-xs-space-around': 'sss-align-content-xs-space-around',
    'justify-content-xs-center': 'sss-justify-content-xs-center',
    'justify-content-xs-flex-end': 'sss-justify-content-xs-flex-end',
    'justify-content-xs-space-between': 'sss-justify-content-xs-space-between',
    'justify-content-xs-space-around': 'sss-justify-content-xs-space-around',
    'justify-content-xs-space-evenly': 'sss-justify-content-xs-space-evenly',
    'spacing-xs-0': 'sss-spacing-xs-0',
    'spacing-xs-1': 'sss-spacing-xs-1',
    'spacing-xs-2': 'sss-spacing-xs-2',
    'spacing-xs-3': 'sss-spacing-xs-3',
    'spacing-xs-4': 'sss-spacing-xs-4',
    'spacing-xs-5': 'sss-spacing-xs-5',
    'spacing-xs-6': 'sss-spacing-xs-6',
    'spacing-xs-7': 'sss-spacing-xs-7',
    'spacing-xs-8': 'sss-spacing-xs-8',
    'spacing-xs-9': 'sss-spacing-xs-9',
    'spacing-xs-10': 'sss-spacing-xs-10',
    'grid-xs-auto': 'sss-grid-xs-auto',
    'grid-xs-true': 'sss-grid-xs-true',
    'grid-xs-1': 'sss-grid-xs-1',
    'grid-xs-2': 'sss-grid-xs-2',
    'grid-xs-3': 'sss-grid-xs-3',
    'grid-xs-4': 'sss-grid-xs-4',
    'grid-xs-5': 'sss-grid-xs-5',
    'grid-xs-6': 'sss-grid-xs-6',
    'grid-xs-7': 'sss-grid-xs-7',
    'grid-xs-8': 'sss-grid-xs-8',
    'grid-xs-9': 'sss-grid-xs-9',
    'grid-xs-10': 'sss-grid-xs-10',
    'grid-xs-11': 'sss-grid-xs-11',
    'grid-xs-12': 'sss-grid-xs-12',
    'grid-sm-auto': 'sss-grid-sm-auto',
    'grid-sm-true': 'sss-grid-sm-true',
    'grid-sm-1': 'sss-grid-sm-1',
    'grid-sm-2': 'sss-grid-sm-2',
    'grid-sm-3': 'sss-grid-sm-3',
    'grid-sm-4': 'sss-grid-sm-4',
    'grid-sm-5': 'sss-grid-sm-5',
    'grid-sm-6': 'sss-grid-sm-6',
    'grid-sm-7': 'sss-grid-sm-7',
    'grid-sm-8': 'sss-grid-sm-8',
    'grid-sm-9': 'sss-grid-sm-9',
    'grid-sm-10': 'sss-grid-sm-10',
    'grid-sm-11': 'sss-grid-sm-11',
    'grid-sm-12': 'sss-grid-sm-12',
    'grid-md-auto': 'sss-grid-md-auto',
    'grid-md-true': 'sss-grid-md-true',
    'grid-md-1': 'sss-grid-md-1',
    'grid-md-2': 'sss-grid-md-2',
    'grid-md-3': 'sss-grid-md-3',
    'grid-md-4': 'sss-grid-md-4',
    'grid-md-5': 'sss-grid-md-5',
    'grid-md-6': 'sss-grid-md-6',
    'grid-md-7': 'sss-grid-md-7',
    'grid-md-8': 'sss-grid-md-8',
    'grid-md-9': 'sss-grid-md-9',
    'grid-md-10': 'sss-grid-md-10',
    'grid-md-11': 'sss-grid-md-11',
    'grid-md-12': 'sss-grid-md-12',
    'grid-lg-auto': 'sss-grid-lg-auto',
    'grid-lg-true': 'sss-grid-lg-true',
    'grid-lg-1': 'sss-grid-lg-1',
    'grid-lg-2': 'sss-grid-lg-2',
    'grid-lg-3': 'sss-grid-lg-3',
    'grid-lg-4': 'sss-grid-lg-4',
    'grid-lg-5': 'sss-grid-lg-5',
    'grid-lg-6': 'sss-grid-lg-6',
    'grid-lg-7': 'sss-grid-lg-7',
    'grid-lg-8': 'sss-grid-lg-8',
    'grid-lg-9': 'sss-grid-lg-9',
    'grid-lg-10': 'sss-grid-lg-10',
    'grid-lg-11': 'sss-grid-lg-11',
    'grid-lg-12': 'sss-grid-lg-12',
    'grid-xl-auto': 'sss-grid-xl-auto',
    'grid-xl-true': 'sss-grid-xl-true',
    'grid-xl-1': 'sss-grid-xl-1',
    'grid-xl-2': 'sss-grid-xl-2',
    'grid-xl-3': 'sss-grid-xl-3',
    'grid-xl-4': 'sss-grid-xl-4',
    'grid-xl-5': 'sss-grid-xl-5',
    'grid-xl-6': 'sss-grid-xl-6',
    'grid-xl-7': 'sss-grid-xl-7',
    'grid-xl-8': 'sss-grid-xl-8',
    'grid-xl-9': 'sss-grid-xl-9',
    'grid-xl-10': 'sss-grid-xl-10',
    'grid-xl-11': 'sss-grid-xl-11',
    'grid-xl-12': 'sss-grid-xl-12'
  };

  const className = clsx(
    {
      [classes.container]: container,
      [classes.item]: item,
      [classes.zeroMinWidth]: zeroMinWidth,
      [classes[`spacing-xs-${String(spacing)}`]]: container && spacing !== 0,
      [classes[`direction-xs-${String(direction)}`]]: direction !== 'row',
      [classes[`wrap-xs-${String(wrap)}`]]: wrap !== 'wrap',
      [classes[`align-items-xs-${String(alignItems)}`]]:
        alignItems !== 'stretch',
      [classes[`align-content-xs-${String(alignContent)}`]]:
        alignContent !== 'stretch',
      [classes[`justify-content-xs-${String(justify || justifyContent)}`]]:
        (justify || justifyContent) !== 'flex-start',
      [classes[`grid-xs-${String(xs)}`]]: xs !== false,
      [classes[`grid-sm-${String(sm)}`]]: sm !== false,
      [classes[`grid-md-${String(md)}`]]: md !== false,
      [classes[`grid-lg-${String(lg)}`]]: lg !== false,
      [classes[`grid-xl-${String(xl)}`]]: xl !== false
    },
    classNameProp
  );

  return <Component className={className} ref={ref} {...other} />;
});

export default Grid;

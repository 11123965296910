/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Liveness from 'services/Liveness';
import { render } from 'pages/renderer';
import { store } from '../store/configureStore';
import { pageMounted, pageUnmounted } from '../actions/applicationActions';

const GenericTemplatedPage = ({ pageJson, pageTitle, documentTitle = "Retail 10" }) => {
  const rendered = pageJson ? render(pageJson) : null;

 
  useEffect(() => Liveness.update(), []);
  useEffect(() => {
    store.dispatch(pageMounted({ pageTitle,taggingId:"page_id" }));
    return () => {
      store.dispatch(pageUnmounted({ pageTitle }));
    };
  }, []);

  useEffect(() => {
    const defaultTitle = "Retail 10";
    const title = documentTitle || defaultTitle;
    document.title = title;
  }, [documentTitle]);
  
  return (
    <div
      onKeyDown={() => Liveness.update(false)}
      onTouchStart={() => Liveness.update(false)}
      onScrollCapture={() => Liveness.update(false)}
      onClick={() => Liveness.update(false)}
    >
      {rendered}
    </div>
  );
};

export default GenericTemplatedPage;

import { connect } from 'react-redux';
import { useCallback, useEffect } from "react";
import { logout } from "services/Liveness";

const ForceLogout = ({ authentication }) => {

    const getExpiresDate = useCallback((timeInMinutes) => {
        const now = new Date();
        const expiry = new Date(now.getTime() + timeInMinutes * 60000);
        return expiry;
    }, []);

    useEffect(() => {
        let expiryTime;
        let timeoutId;
        // in case of saml, authentication stored in nested key name authentication
        if (authentication.name || authentication?.authentication?.name) {
            if (!localStorage.getItem("logoutAfter")) {
                const logoutAfter = JSON.parse(localStorage.config)?.global?.logoutAfter || 1440;
                expiryTime = getExpiresDate(logoutAfter);
                localStorage.setItem("logoutAfter", expiryTime);
            } else {
                expiryTime = localStorage.getItem("logoutAfter");
            }
            const remainingTime = (new Date(expiryTime)) - new Date().getTime();
            timeoutId = setTimeout(() => {
                logout();
            }, remainingTime);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [authentication]);

    return null;
}
const mapStateToProps = state => {
    return {
        authentication: state.authentication
    };
};


export default connect(mapStateToProps)(ForceLogout);

import { store } from 'store/configureStore';
import { locale, apiUrl, envId } from '../settings.json';
import { logout } from 'services/Liveness';
import {
  refreshToken as refreshTokenActionCreator,
  updateToken
} from '../actions/authenticationActions';

const getAuthenticationType = () => {
  const appConfig = localStorage.getItem('config');
  const { storeToken } = (appConfig && JSON.parse(appConfig)) || {};
  if (!storeToken) return 'none';
  return storeToken;
};

const curlRefreshToken = async axios => {
  try {
    const authLocal = localStorage.getItem('auth') || null;
    let refreshTokenLocalStorage = { refreshToken: '' };
    if (authLocal) {
      const authData = JSON.parse(authLocal);
      refreshTokenLocalStorage['refreshToken'] = authData.refresh_token || '';
    }
    const response = await applyRefreshToken(refreshTokenLocalStorage, axios);
    const { access_token, refresh_token, type, id_token } = response;
    if (type == 'localstorage' && access_token) {
      const authLocal = localStorage.getItem('auth') || null;
      const setauthData = JSON.parse(authLocal);
      setauthData.access_token = access_token;
      setauthData.refresh_token = refresh_token;
      localStorage.setItem('auth', JSON.stringify(setauthData));
    }

    if (access_token) {
      store.dispatch(updateToken({ ...response })); // update token in state
      store.dispatch(refreshTokenActionCreator({ ...response })); // add listener in widget
    }
  } catch (error) {
    console.log(error.toJSON());
    logout();
  }
  return 'fulfilled';
};

const applyRefreshToken = async (refreshTokenLocalStorage, axios) => {
  const authenticationType = getAuthenticationType();
  refreshTokenLocalStorage = { ...refreshTokenLocalStorage };
  const tokenUrl = `${apiUrl}/coreapp/refreshToken?locale=${locale}&envId=${window.REACT_APP_ENV_ID || envId}`;
  const setWithCredentials = authenticationType === 'cookies' ? true : false;
  const customAxios = axios.create({
    baseURL: tokenUrl,
    withCredentials: setWithCredentials
  });
  const response = await customAxios({
    method: 'post',
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(refreshTokenLocalStorage)
  });
  if (response && response.data) {
    const { access_token, refresh_token, id_token } = response?.data;
    const type = authenticationType;
    return { access_token, refresh_token, type, id_token };
  }
};

const refreshToken = async (axios, storeToken) => {
  if (storeToken) {
    await curlRefreshToken(axios);
  }
  return 'refreshed';
};

export { refreshToken };

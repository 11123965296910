import Ajv from "ajv";

const ajv = new Ajv({
  useDefaults: true,
  allErrors: true,
  jsonPointers: true
});

class ValidationService {
  validators = {}

  init = () => {
  };

  validateable = (action) => this.validators[action.type]

  validate = async (action) => this.validators[action.type](action.payload)
}

const inst = new ValidationService();
inst.init();
export default inst;

/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Loader } from 'r10-source-library';

const LoadingWrapper = styled.div`
  position: fixed;
  height: 100vh;
  background-color: white;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: ${props => (props.show ? '1000' : '-1')};

  & [class^='Loaderstyle__Loader-sc-1hfrhgh-0'] {
    position: relative;
    top: 160px;
  }
`;

const translation = {
  en: 'Loading your data',
  el: 'Γίνεται επεξεργασία των δεδομένων',
  es: 'Un momento por favor ...'
};

const LoadingIndicator = ({
  isLoading,
  selectedLanguage,
  isError,
  isAuthenticatedApp
}) => {
  const isLogged = useMemo(() => {
    return JSON.parse(localStorage.getItem('auth')) || !isAuthenticatedApp;
  }, [isAuthenticatedApp]);

  // handle remove page scroll from loading & error modal
  useEffect(() => {
    document.body.style.overflow =
      (isLoading && isLogged && !isError) || (isError && !isLoading)
        ? 'hidden'
        : 'auto';
  }, [isLoading, isError]);
  if (!isLoading || !isLogged) return null;

  return (
    <>
      <LoadingWrapper show={isLoading && isLogged} data-testid="loader">
        <Loader srText="Loading" text={translation[selectedLanguage || 'en']} />
      </LoadingWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.loader > 0,
    selectedLanguage: state.language.language,
    isError: state['error-modal'] && state['error-modal'].isOpen,
    isAuthenticatedApp: state?.authentication?.isAuthApp
  };
};

export default connect(mapStateToProps)(LoadingIndicator);

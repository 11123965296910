import { render, registry } from './pages/renderer';
import { shallowEqual, useSelector } from 'react-redux';
import GenericTemplatedPage from './components/GenericTemplatedPage';

registry.GenericPage = GenericTemplatedPage;

const GenericPage = () => {
  const genericPagesJson = useSelector(
    store => store.pages.genericPages,
    shallowEqual
  );
  const auth = useSelector(state => state?.authentication?.scope);
  const config = useSelector(state => state?.config?.data);
  const storeToken = useSelector(state => {
    return state?.config && state?.config?.data?.storeToken
      ? state?.config?.data?.storeToken
      : null;
  });
  const rendered = render(genericPagesJson);
  return (!storeToken && config) || (storeToken && auth)
    ? rendered
    : { ...rendered, props: {} };
};

export default GenericPage;

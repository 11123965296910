import React, { useRef, useEffect } from 'react';

export default function useRefreshTimeout(
  isAuthenticated,
  isExpired,
  callback,
  expireTime,
  storeToken
) {
  const timeoutRef = useRef(null);
  const timeoutCallback = useRef(callback);
  useEffect(() => {
    timeoutCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => timeoutCallback.current();
    if (isAuthenticated && expireTime === 0 && storeToken) {
      tick();
      return;
    }
    if (
      isAuthenticated &&
      !isExpired &&
      expireTime &&
      storeToken &&
      typeof expireTime === 'number'
    ) {
      timeoutRef.current = window.setTimeout(tick, expireTime);
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [expireTime, storeToken]);
}

import { connect } from 'react-redux';
import InactivityWarning from './InactivityWarning';
import React, { useEffect } from 'react';
import Liveness from 'services/Liveness';

const InactivityWarningWrapper = ({ authentication}) => {
  useEffect(() => {
    window.addEventListener('click', () => {
      Liveness.update();
    });
    return () => {
      window.removeEventListener('click', () => {
        Liveness.update();
      });
    };
  }, []);

  return (
    <>
    {authentication?.inactivityWarningShown && <InactivityWarning /> || null}
    </>
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
  };
};


export default connect(mapStateToProps)(InactivityWarningWrapper);
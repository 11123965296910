import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../sharedHistory';

import authentication from './authenticationReducer';
import config from './configReducer';
import language from './languageReducer'
import props from "./propReducer";
import pages from "./pagesReducer";
import loader from "./loaderReducer";


const identityReducer = (state = null, action) => state;

const systemReducers = {
  router: connectRouter(history),
  authentication,
  pages,
  language,
  config,
  loader,
  props
};

const singleReducer = (reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(reduxPerInstance) return null;
  return reducer;
};

const perInstanceReducer = (pckg, instanceId, reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(!reduxPerInstance) return null;
  return (state, action) => {
    if(action.type.startsWith(`@${pckg}/`) || state === undefined) {
      if(action.instanceId) {
        if(Array.isArray(action.instanceId) && action.instanceId.includes(instanceId)) {
          return reducer(state, action);
        } else if (action.instanceId === instanceId) {
          return reducer(state, action);
        } else {
          return state;
        }
      } else {
        return reducer(state, action);
      }
    } else {
      return state;
    }
  };
};

const singleReducersInit = {
};

const userReducersPerInstanceInit = {
};

const userReducersSingle = Object.fromEntries(Object.entries(singleReducersInit).filter(pair => pair[1]));
const userReducersPerInstance = Object.fromEntries(Object.entries(userReducersPerInstanceInit).filter(pair => pair[1]));

export default combineReducers({
  ...systemReducers,
  ...userReducersSingle,
  ...userReducersPerInstance,
});

import { all } from 'redux-saga/effects';

import configSaga from './configSaga';
import languageSaga from './languageSaga';
import navSaga from "./navSaga";

export default function* rootSaga() {
  yield all([
    ...configSaga,
    ...languageSaga,
    ...navSaga,
  ]);
}

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = props => {
  const authJson = localStorage.getItem('auth');
  const auth = authJson ? JSON.parse(authJson) : null;
  const { children: childComponent,regexPath,path, ...rest } = props;
  const isLoggedIn = () => auth;

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      path={regexPath ? new RegExp(regexPath) : path}
      children={isLoggedIn() ? childComponent : <Redirect to="/" />}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.authentication
});

export default connect(mapStateToProps)(PrivateRoute);

/* eslint-disable camelcase */
import axios from 'axios';
import { omit } from 'lodash';
import settings from '../settings.json';
import {
  application,
  apiUrl,
  apigeeConfigApiUrl,
  apigeeConfigApiKey,
  envId
} from '../settings.json';

class ConfigService {
  fetchConfig = async (language = 'el', market = 'gr') => {
    if (settings.externalConfig) {
      try {
        const configUrl = settings.externalConfigUrl
          .replace('{{language}}', language)
          .replace('{{market}}', market);

        const response = await axios.get(configUrl);
        const configObject = response.data.data;
        const firstKey = Object.keys(configObject)[0];
        const rawConfig = configObject[firstKey].items[0];
        const mappedWidgets = {};
        const mappedTranslations = {};

        rawConfig.widgets.forEach(widget => {
          mappedWidgets[widget.name] = {};
          const translation = rawConfig.widgetlabels.find(
            item => item.name === widget.name
          );
          mappedWidgets[widget.name]['translation'] = translation;
          widget.instances.forEach(instance => {
            mappedWidgets[widget.name][instance.id] = omit(instance, ['id']);
          });
        });

        rawConfig.widgetlabels.forEach(widget => {
          mappedTranslations[widget.name] = widget.Labels;
        });

        return {
          configuration: {
            global: {
              ...rawConfig.global,
              termsOrConditionsAssembly: rawConfig.termsOrConditionsAssembly
            },
            widgets: mappedWidgets,
            pages: rawConfig.pages,
            fetchDate: Date.now()
          },
          translation: mappedTranslations
        };
      } catch (error) {
        console.log(error);
      }
    } else {
      const headers = apigeeConfigApiUrl
        ? {
            'content-type': 'application/json',
            Authorization: `Basic ${apigeeConfigApiKey}`
          }
        : {
            'content-type': 'application/json'
          };
      const url = apigeeConfigApiUrl
        ? `${apigeeConfigApiUrl}/${window.REACT_APP_ENV_ID ||
            envId}/${application}`
        : `${apiUrl}/coreapp/configuration/${window.REACT_APP_ENV_ID ||
            envId}/${application}`;
      const { data } = await axios.create()({
        method: 'get',
        url,
        headers
      });
      return {
        ...data,
        fetchDate: Date.now()
      };
    }
  };
  checkSamlAuthorization = async () => {
    try {
      const url = `${apiUrl}/coreapp/saml2/authorize`;
      const Response = await axios.create()({
        method: 'get',
        url,
        withCredentials: true
      });
      return Response.status;
    } catch (error) {
      return 401;
    }
  };

  fetchTaggingSheet = url => {
    fetch(`${url}`, {
      method: 'GET',
      withCredentials: true,
      headers: { 'content-type': 'application/json' },
      mode: 'no-cors'
    })
      .then(response => {
        console.log('response from fetch ', response);
        return response;
      })
      .catch(e => {
        console.log(e);
      });
  };
}

const inst = new ConfigService();
export default inst;

/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Image, Mixins, Heading, Paragraph } from 'r10-source-library';

import CmnLoginLogout from './LoginLogoutBtn';
import logo from '../assets/logo.svg';
import AuthMessage from './AuthMessage';
import { initLogin, signInPageLoaded } from '../actions/authenticationActions';

const Container = styled.div(
  (props) => css`
    height: 100%;
    color: ${props.theme.color.monochrome5.default};
    background: ${props.theme.color.monochrome1.default};
    box-sizing: border-box;
    background-clip: padding-box;
    border-radius: 6px;
    position: relative;
    ${Mixins.elevation(true)};
    z-index: 2;
  `
);

const Content = styled.div(
  (props) => css`
    margin: ${props.theme.spacing.fixed[10]}px -${props.theme.spacing.fixed[6]}px ${props.theme.spacing.fixed[6]}px;
    border-left: solid ${props.theme.spacing.fixed[2]}px ${props.theme.color.secondary1.default};
    padding-left: ${props.theme.spacing.fixed[6]}px;
    padding-right: ${props.theme.spacing.fixed[6]}px;
  `
);

const Media = styled.div(
  (props) => css`
    width: ${props.theme.spacing.fixed[12]}px;
    height: ${props.theme.spacing.fixed[12]}px;
    position: absolute;
    left: 50%;
    top: -${props.theme.spacing.fixed[7]}px;
    transform: translateX(-50%);
  `
);

const ButtonBox = styled.div(
  (props) => css`
    display: flex;
    justify-content: center;
    padding-bottom: ${props.theme.spacing.fixed[4]}px;
  `
);

const Main = styled.div`
  padding: 0;
  min-height: 100vh;
`;

const Section = styled.div`
  background-image: url(img/login.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px;
  position: relative;
`;

const SignIn = ({ authentication, history, dispatchSignInPageLoaded }) => {
  useEffect(() => {
    if (!authentication || !authentication.name) {
      dispatchSignInPageLoaded();
    }
  }, [authentication, dispatchSignInPageLoaded]);

  return !authentication || !authentication.name ? (
    <Main>
      <Section>
        <div style={{ width: '100%', maxWidth: '482px' }}>
          <Container>
            <div style={{ padding: '32px' }}>
              <Media>
                <Image
                  alt="image"
                  sm={{
                    src: logo,
                    width: '80px',
                    height: '80px',
                  }}
                />
              </Media>
              <Content>
                <Heading size={3} text="Welcome to Retail10" />
                <Paragraph>Click below to sign in</Paragraph>
              </Content>
              <ButtonBox>
                <CmnLoginLogout greeting="" history={history} />
              </ButtonBox>
            </div>
          </Container>
        </div>
        <AuthMessage />
      </Section>
    </Main>
  ) : (
    <Redirect to="/dashboard" />
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchInitLogin: () => dispatch(initLogin()),
  dispatchSignInPageLoaded: () => dispatch(signInPageLoaded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

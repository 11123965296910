import React, { useEffect } from 'react';
import * as sec from '@vf-org/smapi-js';
import { PersistGate } from 'redux-persist/integration/react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useSelector } from 'react-redux';
import Liveness from 'services/Liveness';
import { ThemeProvider } from 'styled-components';
import { themeWS10, OverlayProvider } from 'r10-source-library';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import vfTheme from 'vf-comps/build/AppTheme';
import axios from 'axios';
import Cookies from 'js-cookie';
import GenericPages from './GenericPages';
import SharedPages from './sharedPages';
import LoadingIndicator from 'components/LoadingIndicator';
import { applicationMounted } from 'actions/applicationActions';
import { fetchConfig } from 'actions/configActions';
import { changeLanguage } from 'actions/languageActions';
import { checkSamlAuth } from 'actions/authenticationActions';
import settings from 'settings';

import { persistor, store } from 'store/configureStore';
import AppRouter from 'routes/AppRouter';
import RefreshToken from './components/RefreshToken';
/*[Important Note] 
  please not that the blew line is for smapi testing to access sec from window object
  and it should be removed after smapi testing team finish the report   
*/
window.sec = sec;

const s3_hosting_path = require('./constants/s3.js');
const csrfToken = Math.random();

const state = store.getState();
const { language, market } = state['process-flow-stepper'] || {};
store.dispatch(fetchConfig(language, market));

const X_CSRF_TOKEN = 'X-CSRF-TOKEN';
const CORE_APP_VERSION_KEY = 'CORE-APP-VERSION';
const CORE_APP_VERSION_VALUE = '0.1.5';
const ACCEPT_LANGUAGE = 'Accept-Language';
axios.interceptors.request.use(
  req => {
    const appConfig = localStorage.getItem('config');
    const selectedLang = localStorage.getItem('lang');

    const { storeToken, global } = (appConfig && JSON.parse(appConfig)) || {};
    const { endpointSettings } = global || { endpointSettings: [] };

    const isAuthStoredInLocalStorage = storeToken == 'localstorage';
    const isAuthStoredInCookie = storeToken == 'cookies';

    if (selectedLang) {
      req.headers[ACCEPT_LANGUAGE] = selectedLang;
    }
    // added to avoid Cors issue in the noAuth apps
    if (isAuthStoredInCookie) {
      req.withCredentials = true;
      req.headers[CORE_APP_VERSION_KEY] = CORE_APP_VERSION_VALUE;
      req.headers[X_CSRF_TOKEN] = csrfToken;

      Cookies.set(X_CSRF_TOKEN, csrfToken, {
        domain: '.vodafone.com',
        path: '/'
      });
    }

    const settingBasedOnEndpointURl = endpointSettings?.find(elem =>
      req.url?.toLowerCase().startsWith(elem.endpointUrl?.toLowerCase())
    );

    const settingBasedOnBaseURl = endpointSettings?.find(elem =>
      req.url?.toLowerCase().startsWith(elem.baseUrl?.toLowerCase())
    );

    let settingObject = { headers: [] };

    if (settingBasedOnEndpointURl) {
      settingObject = settingBasedOnEndpointURl;
    } else if (settingBasedOnBaseURl) {
      settingObject = settingBasedOnBaseURl;
    }

    settingObject.headers.forEach(header => {
      if (header && header != 'Authorization' && !req.headers[header]) {
        req.headers[header] = global[header];
      } else {
        if (header && header == 'Authorization' && isAuthStoredInLocalStorage) {
          const {
            agentTokenDetails: { token_type, access_token }
          } = JSON.parse(localStorage.getItem('auth'));
          const AuthorizationHeaderValue = `${token_type} ${access_token}`;
          req.headers[header] = AuthorizationHeaderValue;
        }
      }
    });

    return req;
  },
  error => {
    return Promise.reject(error);
  }
);

themeWS10.setBaseAssetLocation(`${s3_hosting_path}/assets/ws10/`);
themeWS10.setAssetLocations('fonts', 'fonts/');
themeWS10.setAssetLocations('icons', 'icons/');
themeWS10.setAssetLocations('logos', 'logos/');
i18next.use(initReactI18next).init({
  lng: 'en',
  resources: {}
});
const App = () => {
  const smapiConfig = useSelector(
    state => state?.config?.data?.global?.smapiConfig
  );

  const tealuimConfig = useSelector(
    state => state?.config?.data?.global?.tealiumConfig
  );

  useEffect(() => {
    if (smapiConfig?.length > 0) {
      smapiInit();
    }
  }, [smapiConfig]);

  useEffect(() => {
    Liveness.setStore(store);
    store.dispatch(applicationMounted());
  }, []);

  useEffect(() => {
    if (
      settings &&
      settings.authProtocol == 'SAML2' &&
      localStorage.getItem('auth')
    )
      store.dispatch(checkSamlAuth());
  }, []);

  useEffect(() => {
    if (
      tealuimConfig?.length > 0 &&
      tealuimConfig[0]?.tealiumIsEnabled &&
      tealuimConfig[0]?.tealiumSdkPath
    )
      tealuimInit();
  }, [tealuimConfig]);

  const tealuimInit = () => {
    (function(a, b, c, d) {
      a = tealuimConfig[0]?.tealiumSdkPath;
      b = document;
      c = 'script';
      d = b.createElement(c);
      d.src = a;
      d.type = 'text/java' + c;
      d.async = true;
      d.id = 'utagScript';
      a = b.getElementsByTagName(c)[0];
      a.parentNode.insertBefore(d, a);
    })();
  };

  const smapiInit = () => {
    const {
      smapiIsEnabled,
      smapiEnvironment,
      smapiCookiesEnabled,
      smapiVerbose,
      smapiNetworkingMonitoringEnabled,
      smapiSpaTrackingOptions,
      smapiHeaders
    } = smapiConfig[0];
    sec.init({
      enabled: smapiIsEnabled,
      environment: smapiEnvironment,
      verbose: smapiVerbose,
      cookiesEnabled: smapiCookiesEnabled,
      networkMonitoringEnabled: smapiNetworkingMonitoringEnabled,
      spaTrackingOptions: {
        enabled: smapiSpaTrackingOptions?.enabled,
        replaceState: smapiSpaTrackingOptions?.replaceState
      },
      headers: {
        'x-vf-trace-source': smapiHeaders?.vfTraceSourceHeader,
        'x-vf-trace-source-version': smapiHeaders?.vfTraceSourceVersionHeader
      }
    });
  };
  return (
    <PersistGate loading={null} persistor={persistor}>
      <MUIThemeProvider theme={vfTheme}>
        <ThemeProvider theme={themeWS10}>
          <themeWS10.globalStyles />
          <OverlayProvider>
            <div className="App">
              <RefreshToken axios={axios} />
              <LoadingIndicator />
              <GenericPages />
              <SharedPages />
              <AppRouter />
            </div>
          </OverlayProvider>
        </ThemeProvider>
      </MUIThemeProvider>
    </PersistGate>
  );
};

export default App;
